@media only screen and (max-width: 600px) {
  .mobile-device {
    width: 600px;
    background-color: 'red';
  }
}

@media (min-width: 253px) {
  .mobile-device {
    display: block;
    height: calc(var(--vh, 1vh) * 100);
    max-width: 100%;
    position: relative;
  }

  .exploreCard {
    display: block;
    height: calc(var(--vh, 1vh) * 100);
    max-width: 100%;
    position: relative;
    overflow: auto;
    padding-bottom: 10px;
  }
}

.gpt-explore-backdrop {
  background-color: rgba(0, 0, 0, 0.8) !important;
}