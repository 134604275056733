.gptpollcontainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 54vh;
  width: 97%;
  outline: 2px solid #0b2a44;
  border-radius: 8px;
  margin: 10px;
  background-color: #000b14;
  margin-top: 10px;
}

.gptimage_container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 400px;
  /* transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); */
  transition-timing-function: ease-in-out;
  transition-duration: 600ms;
}

.image_boxgpt img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  opacity: 0.5;
}

.gptimage_content {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: auto;
  background: linear-gradient(180deg, rgba(0, 11, 20, 0) 4.12%, #000b14 79.12%);
  border-bottom: none;
}

.gptoptions_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-right: 310px;
  margin-top: 10px;
}

.gptoptions_button {
  height: 8vh;
  width: 80vw;
  text-align: left;
  background-color: transparent;
  color: white;
  font-family: "Lato";
  font-weight: 700;
  font-size: 18px;
  padding-left: 20px;
  border: 1px solid #d87c25;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: #d87c25;
  background-size: 50%;
}

/* share poll css  */

.gptsharepollcontainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* height: 70vh;  */
  width: 97%;
  outline: 2px solid #0b2a44;
  border-radius: 8px;
  margin: 10px;
  background-color: #000b14;
  margin-top: 10px;
}

.gptshareoptions_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  /* padding-right:310px ;  */
  margin-top: 10px;
  font-size: 18px;
}

.gptshareoptions_button {
  height: 8vh;
  width: 80vw;
  text-align: left;
  background-color: transparent;
  color: white;
  font-family: "Lato";
  font-weight: 700;
  font-size: 18px;
  padding-left: 20px;
  border: 1px solid #d87c25;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  /* background-color: #d87c25; 
  background-size: 50%;  */
}

.gptshareimage_content {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: auto;
  background: linear-gradient(180deg, rgba(0, 11, 20, 0) 4.12%, #000b14 79.12%);
  border-bottom: none;
}

.gptshareimage_container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 220px;
  /* transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); */
  transition-timing-function: ease-in-out;
  transition-duration: 600ms;
}

.width {
  width: 85vw;
  border: 2px solid #252d33;
  border-radius: 8px;
}

.options_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition-timing-function: linear;
  transition-delay: 100ms;
  transition-duration: 900ms;
  gap: 10px;
}

.selected_progress {
  max-width: 100vw;
  border-radius: 8px;
  height: 7vh;
  background-color: #d87c25;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bar_number {
  padding-right: 30px;
  padding-left: 30px;
  font-family: "Lato";
  color: #e0e0e0;
  font-style: normal;
  min-width: 88vw !important;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}
