.container {
  display: flex;
  justify-content: center;
  height: 80vh;
  width: -webkit-fill-available;
  border: 2px solid #1c3d5a;
  border-radius: 15px;
  margin: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.image {
  width: 50vw;
}

.enter_contest {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-top: -30px;
}

.enter_contest h2 {
  font-family: "Lato";
  font-weight: 700;
  font-size: 16px;
  margin: 0;
}

.enter_contest h3 {
  font-family: "Merriweather";
  font-size: 35px;
  color: #d87c25;
  margin: 0;
}

.enter_contest h4 {
  font-family: "Lato";
  font-weight: 700;
  font-size: 16px;
  margin: 0;
}

.paragraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}

.paragraph h2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.input_number {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input_number input {
  background: rgba(3, 11, 19, 0.5);
  border: 1px solid #d87c25;
  border-radius: 8px;
  width: 70vw;
  height: 5vh;
  color: white;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  padding-left: 10px;
}

.input_number p {
  margin: 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.button {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button button {
  background: #ffffff;
  border-radius: 8px;
  width: 80vw;
  height: 7vh;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.button p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}

.text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}
