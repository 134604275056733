@media (max-width: 412px) {
  .myPolls {
   margin-left: 100;
  }
}

/* pollName */


@media (min-width: 253px) {
  .pollName{ 
  }
}