/* This is the container of Gyan.gg */
.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 73vh;
  outline: 2px solid #0b2a44;
  border-radius: 8px;
  margin: 15px;
  background-color: #000b14;
  transition: all 0.3s ease;
}

.container.show-next {
  transition: transform 0.01s linear;
  /* transform: rotateY(90deg) translateX(-100%) scale(0.1); */
  /* transform: rotateY(90deg) scale(0.1); */
  transform: translateX(-100%) scale(0);
  /* animation: slide-in-right 0.5s forwards; */
}

.container.show-previous {
  transition: transform 0.01s linear;
  transform: translateX(+100%) scale(0);
}

/* @keyframes slide-in-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
} */

.container_knowmore {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 76vh;
  outline: 2px solid #0b2a44;
  border-radius: 8px;
  margin: 15px;
  background-color: #000b14;
}

.container_answer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 72vh;
  gap: 20px;
  outline: 2px solid #0b2a44;
  border-radius: 8px;
  margin: 15px;
  padding-bottom: 5px;
  background-color: #000b14;
  transition: all 0.3s ease;
}

.container_answer.show-nexts {
  transition: transform 0.01s linear;
  transform: translateX(-100%) scale(0);
}

/* This is the image container of Gyan.gg */
.image_container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 40vh;
  transition-timing-function: linear;
  transition-duration: 600ms;
}

.image_box {
  object-fit: contain;
}

.buttonText {
  text-transform: none;
}

.knowmore_image_container {
  display: flex;
  flex-direction: column;
  position: relative;
  transition-timing-function: linear;
  transition-duration: 600ms;
}

.image {
  width: 100%;
  height: 300px;
}

.answer_container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 300px;
}

.answer_container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  opacity: 0.5;
}

.knowmore_image_container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  opacity: 0.5;
}

.image_container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  opacity: 0.5;
}

/* This is the image content container of Gyan.gg */
.image_content {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: auto;
  background: linear-gradient(180deg, rgba(0, 11, 20, 0) 4.12%, #000b14 79.12%);
  border-bottom: none;
}

.image_content_knowmore {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  background: linear-gradient(180deg, rgba(0, 11, 20, 0) 4.12%, #000b14 79.12%);
  border-bottom: none;
  justify-content: space-between;
}

.tag {
  background-color: rgba(216, 124, 37, 0.6);
  color: white;
  max-width: fit-content;
  text-align: center;
  padding: 0.3rem 0.7rem 0.3rem 0.7rem;
  border-radius: 36px;
  font-family: "Lato";
  font-weight: 500;
  font-size: 14px;
}

.padding_content {
  display: flex;
  flex-direction: column;
  gap: 11px;
  padding: 0px 20px 0px 20px;
}

.blur {
  height: 3vh;
  width: 100%;
  background-color: transparent;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-bottom: 10px solid rgba(35, 34, 34, 0.06);
}

.question_width {
  max-width: 80%;
}

.question {
  display: flex;
  justify-content: space-between;
  font-family: "Merriweather";
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
}

.options_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition-timing-function: linear;
  transition-delay: 100ms;
  transition-duration: 900ms;
  gap: 10px;
}

.options_button {
  height: 8vh;
  width: 84vw;
  text-align: left;
  background-color: transparent;
  color: white;
  font-family: "Lato";
  font-weight: 700;
  font-size: 18px;
  padding-left: 20px;
  border: 1px solid #d87c25;
  border-radius: 8px;
}

.Share_btn {
  display: flex;
  align-items: center;
  max-width: 80vw;
  height: 5vh;
  border-radius: 5px;
}

.answer_responded {
  display: flex;
  align-items: center;
  gap: 5px;
}

.answer_responded h2 {
  font-family: "Lato";
  font-weight: 700;
  font-size: 20px;
  color: #d87c25;
}

.answer_responded span {
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
}

.people_responded {
  display: flex;
  align-items: center;
  gap: 4px;
}

.people_responded h2 {
  font-family: "Lato";
  font-weight: 700;
  font-size: 16px;
}

.people_responded span {
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
}

.skip {
  bottom: 0;
}

.summary {
  padding: 20px;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  overflow: scroll;
  scroll-behavior: smooth;
}

.summary_font {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  opacity: 0.7;
}

.width {
  width: 85vw;
  border: 2px solid #252d33;
  border-radius: 8px;
}

.progress {
  max-width: 100vw;
  border-radius: 8px;
  height: 7vh;
  background-color: #553521;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected_progress {
  max-width: 100vw;
  border-radius: 8px;
  height: 7vh;
  background-color: #d87c25;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bar_number {
  padding-right: 30px;
  padding-left: 30px;
  font-family: "Lato";
  color: #e0e0e0;
  font-style: normal;
  min-width: 70vw !important;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.back_btn {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #000b14;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  padding: 5px 10px;
  font-size: 16px;
  width: fit-content;
  height: 2vh;
  align-self: flex-end;
  margin-top: 8px;
  margin-right: 8px;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-duration: 600ms;
}

.next {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #000b14;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  padding: 5px 10px;
  font-size: 16px;
  width: fit-content;
  height: 2vh;
  align-self: flex-end;
  margin-top: 8px;
  margin-right: 8px;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-duration: 600ms;
}

.icons {
  margin-top: 3px;
  height: 15px;
  width: 15px;
  
}

.back_icons {
  fill: #e0e0e0;
  margin-top: 3px;
  height: 15px;
  width: 15px;
}

.next_poll {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #000b14;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  padding: 5px 10px;
  font-size: 16px;
  width: fit-content;
  height: fit-content;
  align-self: flex-end;
  margin-top: 8px;
  margin-right: 8px;
  /* visibility: hidden; */
}

.menu_navigation {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
}

.text_fonts {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.share_it {
  display: flex;
  align-items: center;
  justify-content: center;
}

.share_button {
  max-width: 35vw;
  padding: 5px 7px 5px 5px;
  border-radius: 5px;
  background-color: white;
  color: black;
  font-family: "Lato";
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 3.7vh;
}

.share_icons {
  height: 15px;
  width: 15px;
  fill: black;
}

.share_iconss {
  height: 20px;
  width: 20px;
  fill: #d87c25;
  margin-left: 11px;
  margin-top: 4px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: -30px;
  margin-left: 14px;
  margin-right: 21px;
}

.generate_font {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.trapezoid {
  display: flex;
  align-items: flex-end;
  width: 83.5vw;
  height: 145px;
  background: linear-gradient(180deg, #00070c 0%, rgba(0, 7, 12, 0) 96.88%);
  border: 2px solid transparent;
  border-bottom: none;
  border-image: linear-gradient(180deg, rgba(216, 124, 37, 0) 0%, #d87c25 100%);
  border-image-slice: 1;
  box-sizing: border-box;
  padding-bottom: 20px;
  transform: perspective(200px) rotateX(27deg);
  margin-top: 35px;
  margin-left: 33px;
  margin-right: 0px;
}

.transform_image {
  display: flex;
  height: inherit;
  padding-bottom: -88px;
  margin-bottom: -160px;
  z-index: 1;
  position: relative;
  justify-content: center;
}

.rewards_container {
  display: flex;
  align-items: center;
  width: 98.5vw;
  height: 7vh;
  justify-content: center;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(180deg, #d87c25, rgba(216, 124, 37, 0)) 1;
  transform: skewY(180deg);
  margin-left: 1px;
  margin-top: 2.5px;
}

.rewards_container > * {
  transform: skewY(-180deg);
}

.categories_container {
  overflow-x: scroll;
  scroll-behavior: smooth;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.pills {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 2px solid #868686;
  border-radius: 36px;
  padding: 6px 10px 6px 10px;
  color: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.cross {
  display: flex;
  align-items: center;
  gap: 4px;
}

.info_icon {
  height: fit-content;
  width: fit-content;
  /* animation: scaleAnimation 1s infinite alternate; */
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.rewards_image {
  height: auto;
  width: auto;
}

.Okay {
  border: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  color: #d87c25;
  background-color: transparent;
}

.Okays {
  border: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  opacity: 0.6;
  background-color: transparent;
}

.mask {
  opacity: 0.9;
}

.swipe_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.info_btn_tour {
  margin-right: 5vw;
}

.newHeader {
  display: flex;
  align-items: center;
  gap: 7px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}

.newHeader img {
  height: 23px;
  width: 23px;
}

.tour_answer {
  height: auto;
  width: auto;
}

.swipenew {
  display: flex;
  align-items: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #d87c25;
  height: 4vh;
  padding-bottom: 3px;
}

.swipenewicon {
  height: 20px;
  width: 20px;
  fill: #d87c25;
}

.swipenewicons {
  height: 20px;
  width: 20px;
  fill: #d87c25;
  margin-left: -10px;
}

.moveit {
  display: flex;
  align-items: center;
  animation: swipeAnimation 1s infinite alternate;
}

@keyframes swipeAnimation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-height: 400px) {
  .container {
    height: fit-content;
  }

  .container_answer {
    height: fit-content;
  }

  .container_knowmore {
    height: fit-content;
  }

  .options_button {
    height: 4rem;
  }

  .selected_progress {
    height: 4rem;
  }

  .progress {
    height: 4rem;
  }

  .next {
    height: 5vh;
  }

  .next_poll {
    height: 5vh;
  }
}
