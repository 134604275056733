html,
body {
  overflow-x: hidden;
}

.App {
  display: none;
  /* text-align: center;
  max-width: 600px;
  width: 600px; */
  /* border-color: black; */
  
}
/* @media only screen and (max-width: 600px) {
  .App {
   width: auto;

  }
} */

@media (min-width: 325px) {
  .App {
    display: block;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    overflow-x: hidden;
  }
  div::-webkit-scrollbar {
    display: none;
    scroll-behavior: smooth !important; 
  }
}
@media (max-width: 685px) {
  .App {
    display: block;
    height: calc(var(--vh, 1vh) * 100);
    width: auto;
    overflow-x: hidden;
  }
  div::-webkit-scrollbar {
    display: none;
    scroll-behavior: smooth !important; 
  }
}
