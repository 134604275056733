.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*spaces between children*/
  z-index: 100;
  /* the page will always be at the top*/
  border-bottom: 1px solid #000000;

  color: #fff;
}

/* .header__logo {
    object-fit: contain;
    height: 40px;
} */

.header__icon {
  /* padding: 20px; */
  margin: 0 auto;
}
