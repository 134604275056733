.card {
  display: none;
}

@media (min-width: 253px) {
  .card {
    display: block;
    height: calc(var(--vh, 1vh) * 100);
    max-width: 100%;
    position: relative;
    box-shadow: 0px 18px 53px 0px rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }
}

.imagePoll {
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
@media (min-width: 253px) {
  .imagePoll {
    display: block;
    width: 100%;
    position: relative;
    box-shadow: 0px 18px 53px 0px rgba(0, 0, 0, 0.3);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.tinderCards__cardContainer {
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.swipe {
  position: absolute;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: white;
}

.my-class {
  border-color: #5c6067 !important;
  height: 200;
  width: "500px";

  min-width: "0px !important";

  background-color: "red";
}
.my-caret-class {
  background: #5c6067 !important;
}
.my-overlay-class {
  background: #5c6067 !important;
}
